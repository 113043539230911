.video-container {
  display: grid;
  gap: 4rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-items: center;
  /* max-width: 80%; */
  padding: 2rem 0;
}

@media screen and (min-width: 768px) {
  .video-container {
    max-width: 80%;
  }
}

/* @media screen and (min-width: 1200px) {
  .video-container {
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  }
} */
