@media screen and (min-width: 768px) {
  .button-container {
    flex-direction: row;
    place-content: center;
  }
}

.btn:is(input[type='checkbox']:checked),
.btn:is(input[type='radio']:checked) {
  background-color: #5e81ac !important;
  color: #1d232a !important;
  border: none;
  width: 145px;
}
