.icon-style {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  color: rgba(255, 0, 0, 0.3);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.icon-style:hover {
  transition: all 0.3s ease-in-out;

  /* animation: none; */
  color: #ffffff;
}
