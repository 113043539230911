@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap'); */

@font-face {
  font-family: 'Trebuchet MS';
  font-style: normal;
  font-weight: normal;
  src: local('Trebuchet MS'), url('./fonts/trebuc.woff') format('woff');
}

@font-face {
  font-family: 'Trebuchet MS Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Trebuchet MS Italic'), url('./fonts/Trebuchet-MS-Italic.woff') format('woff');
}

@font-face {
  font-family: 'SourceSans3-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('SourceSans3-Regular'), url('./fonts/SourceSans3-Regular.ttf') format('ttf');
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

:root {
  background-color: #1d232a;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  font-family: Trebuchet MS, SourceSans3-Regular;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem 0.5rem;
}

.section {
  width: 100%;
  height: 100%;
  /* max-width: 420px;
  min-height: 400px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.4);
  background: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(29, 35, 42, 1) 35%);
  /* border-radius: 1rem; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  padding-bottom: 1rem;
}

.inset-field {
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
}

/* .uid-container {
  position: relative;
}

#uidnote {
  position: absolute;
  bottom: 0;
} */

a,
a:visited {
  color: #fff;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}

.menu {
  z-index: 2;
}

.container {
  position: relative;
  width: 40%;
  overflow: hidden;
  padding-top: 22.5%;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

@media screen and (max-width: 1200px) {
  .container {
    width: 70%;
    padding-top: 39.5%;
  }
}

@media screen and (max-width: 768px) {
  .main {
    padding: 0;
  }
  .container {
    width: 100%;
    padding-top: 56.25%;
  }
  .task-ul li {
    flex-direction: column;
    text-align: center;
  }
}

.flexGrow {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  background-color: #e80d0d;
}

.flex-center {
  @apply flex items-center justify-center;
}

.flex-start {
  @apply flex items-start justify-start;
}

.flex-end {
  @apply flex items-center justify-end;
}

.flex-between {
  @apply flex items-center justify-between;
}
