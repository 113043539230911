.shadow {
  box-shadow: 0 2px 15px rgb(0 0 0 / 68%);
}

/* Chat List */
.ce-wrapper {
  background-color: #0e1113 !important;
}

.ce-chats-container {
  background-color: #1a2024 !important;
  border-radius: 0px !important;
  overflow: scroll;
}

.ce-chat-list {
  overflow: hidden !important;
}

.ce-chat-form-container {
  background-color: #1a2024 !important;
  color: white;
  margin-bottom: 2rem;
}

.ce-chat-card {
  margin: 0px !important;
  margin-bottom: 0.5rem !important;
}

.ce-chat-card-loading-bar {
  background-color: #535a6d !important;
}

.ce-active-chat-card {
  background-color: #374151 !important;
}

.ce-chat-title-text {
  color: white !important;
}

.ce-chat-subtitle-text {
  color: hsla(0, 0%, 100%, 0.568) !important;
}

.ce-chat-unread-dot {
  background-color: white !important;
}

/* Chat Feed */

.ce-chat-title-container {
  background-color: #0e1113 !important;
  border-radius: 0px !important;
}

.ce-chat-list-column {
  flex: 1 0 33% !important;
  /* min-width: 33% !important; */
}

.ce-chat-feed-column {
  flex: 1 !important;
  max-width: 100% !important;
}

.ce-chat-feed-container {
  background-color: #0e1113 !important;
}

.ce-primary-button {
  background-color: #374151 !important;
  padding-bottom: 5px !important;
}

.ce-primary-button > span {
  display: inline-flex;
  margin-bottom: 3px;
}

.ce-message-date-text {
  color: hsla(0, 0%, 100%, 0.568) !important;
}

.ce-my-message-bubble {
  background-color: #726dfe !important;
}

.ce-my-message-sinding-bubble {
  background-color: #938ffe !important;
}

.ce-my-message-timestamp {
  color: #9a97fc !important;
}

.ce-their-message-timestamp {
  color: hsla(0, 0%, 100%, 0.568) !important;
}

#ce-send-message-button {
  background-color: #9a97fc !important;
}

#ce-ice-breaker-gif {
  max-width: 115px !important;
  margin-top: 30px !important;
}

.ce-social-message-form {
  background-color: #4a5162 !important;
}

.ce-message-input-form {
  padding-bottom: 10px !important;
}

#msg-textarea {
  width: calc(100% - 140px) !important;
  position: relative !important;
  top: 4px !important;
  border-width: 0px !important;
  background-color: #4a5162 !important;
  color: white !important;
}
#msg-textarea::placeholder {
  color: hsla(0, 0%, 100%, 0.568) !important;
}

#ce-upload-document-icon {
  color: white !important;
}

.ce-feed-container-bottom {
  height: '44px';
}

.ce-message-images-row {
  background-color: #0e1113 !important;
}

.ce-their-message-sender {
  color: hsla(0, 0%, 100%, 0.568) !important;
}

.ce-their-message-bubble {
  background-color: #535a6d !important;
  color: rgba(255, 255, 255, 0.705) !important;
}

/* Chat Settings */

.ce-settings {
  background-color: #1a2024 !important;
  border-radius: 0px !important;
  overflow: hidden !important;
}

.ce-chat-title-form .ce-text-input {
  background-color: #1a2024;
  color: white;
}

.ce-chat-settings-container {
  display: flex;
  flex-direction: column;
}

.ce-section-title-container {
  background-color: #1a2024 !important;
  color: white;
}

#ce-options-drop-down {
  background-color: #1a2024 !important;
  color: white;
}

.ce-person-text {
  color: hsla(0, 0%, 100%, 0.568);
}

.ce-danger-button {
  background-color: #4a5162 !important;
  border: 1px solid #bf616a !important;
  color: #bf616a !important;
}

.ce-autocomplete-close {
  background-color: white !important;
  border-radius: 0px !important;
}

/* CUSTOM FEED */

.chat-feed {
  height: calc(100% - 55px) !important;
  width: 100%;
  overflow: scroll;
  /* border-left: none !important;*/
  /* border-right: 1px solid rgb(204, 202, 202); */
}

.chat-title-container {
  width: calc(100% - 36px);
  padding: 18px;
  text-align: center;
}

.message-row {
  float: left;
  width: 100%;
  display: flex;
  margin-left: 18px;
  align-items: center;
}

.message-block {
  width: 100%;
  display: inline-block;
}

/* .message-avatar {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  color: white;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 48px;
} */

.message {
  padding: 12px;
  font-size: 16px;
  border-radius: 6px;
  max-width: 60%;
}

.message-image {
  margin-right: 18px;
  object-fit: cover;
  border-radius: 6px;
  height: 30vw;
  /* width: 30vw; */
  max-height: 200px;
  max-width: 200px;
  min-height: 100px;
  min-width: 100px;
}

.read-receipts {
  position: relative;
  bottom: 6px;
}

.read-receipt div div {
  font-size: 0.5rem !important;
  line-height: 0.75rem !important;
  width: 1rem !important;
}

.message-form-container {
  position: absolute;
  bottom: 0px;
  width: 100%;
  /* padding: 5px 18px 15px 18px; */
  background-color: #0e1113;
}

.inset-field {
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
}

.message-form {
  display: flex;
  flex-direction: row;
  height: 65px;
  gap: 0.5rem;
  width: 100%;
  overflow: hidden;
  padding: 4px;
  border-radius: 12px;
  /* border: 1px solid #8a8a8a; */
  background-color: 0e1113;
  color: #0e1113;
  box-shadow: 0px 15px #0e1113;
}

.message-input {
  height: 100%;
  /* width: calc(100% - 132px); */
  background-color: white;
  border: 1px solid white;
  padding: 0.5rem 0.5rem;
  outline: none;
  font-size: 16px;
  /* margin: 0.25rem; */
}

/* .image-button {
  cursor: pointer;
  padding: 0px 12px;
  height: 100%;
}

.send-button {
  height: 42px;
  background-color: white;
  border: 1px solid white;
  padding: 0px 18px;
  cursor: pointer;
}

.send-icon {
  top: 1px;
  position: relative;
  transform: rotate(-90deg);
}

.picture-icon {
  top: 1px;
  position: relative;
  font-size: 14px;
} */

/* FORM STYLES */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-size: 62.5%; */
}

.ce-chat-engine .input {
  color: #333;
  font-size: 1.2rem;
  margin: 0 auto;
  padding: 1.5rem 2rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
  outline: none;
  margin-bottom: 25px;
}

.ce-chat-engine .form {
  width: 400px;
}

.ce-chat-engine .title {
  text-align: center;
  color: white;
  margin-bottom: 30px;
  width: 100%;
  font-size: 2.3em;
}

.ce-chat-engine .button {
  border-radius: 4px;
  border: none;
  background-color: white;
  color: black;
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  padding: 20px;
  width: 200px;
  transition: all 0.4s;
  cursor: pointer;
  margin: 5px;
  width: 90%;
}
.ce-chat-engine .button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.4s;
}
.ce-chat-engine .button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
.ce-chat-engine .button:hover span {
  padding-right: 25px;
}
.ce-chat-engine .button:hover span:after {
  opacity: 1;
  right: 0;
}

.ce-chat-engine .chat-subtitle {
  color: #eee6e8;
  font-weight: 600;
  font-size: 12px;
  padding-top: 4px;
}

.ce-chat-engine .chat-title {
  color: #eee6e8;
  font-weight: 800;
  font-size: 24px;
}

#new-chat-plus-button {
  border-radius: 6px !important;
}

/* @media screen and (max-width: 768px) {
  .ce-chat-list-column,
  .ce-settings-column {
    width: 0% !important;
    flex: 0 0 0% !important;
    max-width: 0% !important;
    display: none !important;
  }
} */
